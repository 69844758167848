.gallery-properties {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: #1a1c1d;
    overflow: auto;
    height: 75vh;
    justify-items: center;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.gallery-properties::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.gallery-properties {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
