.contact-info {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #FFB144;
    background-color: #1b1b1b; /* Assuming a dark background */
    color: #FFF;
}

.contact-section {
    margin-bottom: 20px;
    text-align: left;
}

.contact-section h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-section p,
.contact-section a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #FFB144;
    font-size: 14px;
    margin: 0;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}

.contact-info.rtl .contact-section {
    text-align: right;
}
