@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color: #1b1b1b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.App-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #FFB144;
  font-size: 40px;
  margin-bottom: 30px;
  align-self: center;
}

.service-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .service-container {
    grid-template-columns: 1fr; /* Stack items vertically on small screens */
  }
}

.service-box {
  font-family: "Montserrat", sans-serif;
  border: 0px solid #61dafb;
  margin: 10px;
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.service-box {
  font-family: "Montserrat", sans-serif;
  border: 0px solid #61dafb;
  margin: 10px;
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service-box h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #5d4f35;
  font-size: 18px;
  margin-bottom: 0px;
}

.service-box p {
  font-family: "Montserrat", sans-serif;
  font-style: bold;
  color: #1b1b1b;
  font-size: 14px;
  margin-top: 15px;
}

.service-box img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.service-box .text-section {
  background-color: #FFB144;
  width: 100%;
  height: 40%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.common-questions {
  font-family: "Montserrat", sans-serif;
  color: #FFB144;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 5%;
  background-color: #5d4f35;
  width: 100%;
  height: 100%;
  display: block;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.common-questions h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fbcb71;
  font-size: 32px;
  margin-bottom: 50px;
  text-align: center;
}

.question-box {
  border: 2px solid #FFB144;
  margin: 1% 2%;
  margin-top: 50px;
  width: 25%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding-top: 20px;
  transition: border-color 0.5s ease;
}

.question-box:hover {
  border-color: #ffffff;
}

.question-box-wrapper {
  display: flex;
  justify-content: center;
}

.question-box h4 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 18px;
  color: #ffffff;
}

.question-box p {
  font-family: "Montserrat", sans-serif;
  font-style: bold;
  color: #FFB144;
  font-size: 16px;
}

.property-box {
  border: 0px solid #61dafb;
  margin: 10px;
  width: 350px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.property-box img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.property-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b;
  padding-top: 20px;
  color: #FFB144;
  overflow: hidden;
}

.property-container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 600;
}



.embla {
  overflow: hidden;
  height: 100%;
  width: 100%;

}

.embla__container {
  display: flex;
  padding-bottom: 30px;

}

html[dir="rtl"] .embla__container {
  flex-direction: row-reverse;
}

.embla__slide {
  flex: 0 0 33.3333%;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  min-width: 0;
}

.embla__slide.is-active {
  opacity: 1;

}

.embla__slide img {
  width: 100%;
  border: #fbcb71 0px solid;
  padding: 10px;
  height: 400px;
  object-fit: cover;
}



.testimonials-section {
  font-family: "Montserrat", sans-serif;
  color: #FFB144;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 5%;
  background-color: #1b1b1b;
  width: 100%;
  height: 100%;
  display: block;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.testimonial-box {
  border: 0px solid #61dafb;
  margin: 20px;
  width: 350px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testimonial-box p {
  font-family: "Montserrat", sans-serif;
  font-style: bold;
  color: #ffffff;
  font-size: 14px;
  margin-top: 15px;
}

.testimonials-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testimonial-box .quotation-mark {
  font-size: 90px;
  color: #FFB144;

  position: relative;
}

.testimonial-box .quotation-mark::after {
  content: "";
  position: absolute;
  bottom: 0px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  /* Adjust as needed */
  height: 30px;
  /* Adjust as needed */
  border-radius: 50%;
  background-color: #FFB144;
}

.contact-info-section {
  border: 0.5px solid #FFB144;
  width: 80%;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.contact-info-section h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  margin-bottom: 20px;
}

.contact-info-section a {
  font-family: 'Montserrat', sans-serif;
  font-style: bold;
  font-weight: 600;
  color: #FFB144;
  font-size: 14px;
  margin-top: 0px;
  padding-bottom: 0px;
  text-decoration: none;
}

.contact-info-section a:hover {
  text-decoration: underline;
}

.contact-info-section p {
  font-family: 'Montserrat', sans-serif;
  font-style: bold;
  font-weight: 600;
  color: #FFB144;
  font-size: 14px;
  margin-top: 0px;
}

.contact-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact-info-box.rtl .contact-info-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.contact-info-details {
  flex: 1;
  text-align: left;
  justify-content: space-between;
}

.contact-info-box.rtl .contact-info-details {
  text-align: right;
}

.footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  justify-content: center;
}

.hero-bg {
  width: 100%;
  height: 100%;
}

.footer img {
  max-width: 60%;
  height: auto;
}

.social-media-icons {
  display: flex;
  justify-content: space-between;
  width: 120px;
  padding-bottom: 10px;
}

.social-media-icons a {
  color: #FFB144;
  font-size: 30px;
}

.fullscreen-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.inner-container {
  flex: 1;
}

.vertical-split {
  display: flex;
  flex-direction: column;
  height: 100%;
}



.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.logo {
  /* Adjust as needed */
  width: 60%;
  height: auto;
  margin-top: 20px;
  align-items: center;
}

.title-container {
  text-align: center;
  height: 65%;
}

.hero-title {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  display: block;
  padding-left: 30px;
  padding-right: 30px;
}

.white-title {
  color: #ffffff;
}

.colored-title {
  color: rgb(255, 203, 113);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 0;
  width: 90vw;
  /* Adjust as needed */
  height: 90vh;
  /* Adjust as needed */
  margin: 5vh auto;
  /* Centers the container vertically and horizontally */
  border-top: #fbcb71 5px solid;
  /* Optional, just to visualize the containers */
  gap: 20px;
  /* Adjust as needed */
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
}

.grid-item p {
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  line-height: 30px;
  color: #ffffff;
  font-size: 20px;
  margin-top: 15px;
}

.about-Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-form {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFB144;
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  background-color: #1b1b1b;
  color: #FFB144;
  padding-top: 30px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

.form-title {
  text-align: center;
  width: 100%;
  color: #FFB144;
}

.contact-from label {
  margin-bottom: 10px;

}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #FFB144;
  background-color: #1b1b1b;
  color: #FFB144;
}

.contact-form button {
  padding: 10px;
  border: 1px solid #FFB144;
  background-color: #1b1b1b;
  color: #FFB144;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.language-switcher {
  display: flex;
  padding: 10px;
  top: 10px;
  position: absolute;
  gap: 10px;
}

.language-button {
  background-color: #FFB144;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-button:hover {
  background-color: #1b1b1b;
}

.footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo-container img {
  width: 50%;
  height: auto;
}

.learn-more-button {
  background-color: #1b1b1b;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.learn-more-button:hover {
  background-color: #FFB144;
}

.learn-more-button a {
  color: #FFFFFF;
  text-decoration: none;
}

.map-container {
  width: 80%;
  padding-bottom: 30px;
}

.map {
  width: 100%;
  height: 400px;
  /* Adjust the height as needed */
  border-radius: 10px;
}




/* Styles for smaller screens */
@media (max-width: 768px) {
  .service-container {
    flex-direction: column;
    align-items: center;
  }

  .question-box-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .question-box {
    width: 80%;
  }

  .property-container {
    flex-direction: column;
    align-items: center;
  }

  .property-box {
    width: 80%;
  }

  .fullscreen-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: 90vw;
    height: auto;
    margin: 5vh auto;
  }

  .fullscreen-container.grid-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;

  }

  .hero-title.white-title {
    color: #ffffff;
    font-size: 40px;
  }

  .hero-title.colored-title {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    text-align: center;
  }

  .hero-bg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .title {
    font-size: 40px;
    text-align: center;
    align-self: center;
  }

  .testimonials-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .contact-info-section {
    width: 100%;
    border: 0.5px solid #FFB144;
    border-left: 0.5px solid #FFB144;
  }

  .embla__container {
    display: flex;
    flex-direction: row;
  }


  .embla__slide {
    flex: 0 0 90%;
  }

  .contact-form {
    width: 80%;
    padding-top: 10px;
  }

  .contact-info-box {
    width: 90%;
    border: #FFB144 1px solid;


  }





}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .service-container {
        grid-template-columns: repeat(2, 1fr); /* Show two columns on tablets */
    }

    .service-box {
        height: auto; /* Allow height to adjust based on content */
    }

    .common-questions {
        font-size: 24px; /* Adjust font size for tablet */
    }

    .common-questions h2 {
        font-size: 28px; /* Adjust heading size for tablet */
    }

    .property-container {
        flex-direction: column; /* Stack property boxes vertically */
        align-items: center;
    }

    .property-box {
        width: 80%; /* Adjust width for property boxes */
    }
}