/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1b1b1b;
    padding: 0 20px;
    box-sizing: border-box;
    height: 100px;
}

.header-section1, .header-section2 {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1; /* Use flex grow instead of a fixed width */
    min-width: 0; /* Prevent overflow */
    height: 100%; /* Ensure sections take full height of the header */
}

.header-section2 {
    justify-content: flex-end;
}

.logo img {
    height: 80px;
    /* Add a max-width property to ensure the logo doesn't grow too large */
}

.language-switcher {
    display: flex;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
    /* Add a max-width property to ensure the buttons don't grow too large */
    max-width: 100%; 
    flex-wrap: nowrap;
    align-items: center;
}

.lang-button {
    background-color: #FFB144;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lang-button:hover {
    background-color: #1b1b1b;
}