/* ServicePageLayout.css */
.service-page-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #1b1b1b;
    max-width: 100%;
    margin: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.image-container img {
    max-width: 50%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.content-container {
    text-align: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.content-container h1 {
    font-size: 2em;
    color: #FFB144;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.content-container p {
    font-size: 1.2em;
    color: #ffffff;
}

.contact-form-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.footer-logo-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.footer-logo-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%; /* Adjust the width as needed */
    margin-left: auto;
    margin-right: auto;
}

/* ServicePageLayout.css */
/* Phone Media Query */
@media only screen and (max-width: 600px) {
    .service-page-layout {
        padding: 10px;
    }

    .image-container img {
        max-width: 100%;
    }

    .content-container h1 {
        font-size: 1.5em;
    }

    .content-container p {
        font-size: 1em;
    }
}

/* Tablet Media Query */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .service-page-layout {
        padding: 15px;
    }

    .image-container img {
        max-width: 75%;
    }

    .content-container h1 {
        font-size: 1.75em;
    }

    .content-container p {
        font-size: 1.1em;
    }
}
